import './ResiloLogo.css';
import avatar from '../../assets/resilo-avatar.jpg';
import React from "react";

const ResiloLogo = () => {
  return (
    <div className="resilo-avatar">
      <img src={avatar} alt="Resilo"/>
      <span>Resilo</span>
    </div>
  )
}

export default ResiloLogo;
