import React from "react";
import './Dropdown.css';
import moreIcon from "../assets/icons/more.png";

export const SEND_HISTORY = "SEND_HISTORY";
export const RESET_CHAT = "RESET_CHAT";

export class Dropdown extends React.Component {
    selectItem = (item) => {
        this.props.onSelection(item);
    }

    render() {
        const {isListOpen, onClick} = this.props;

        return (
            <div className="dropdown-wrapper">
                <button
                    className="dropdown-button"
                    onClick={onClick}
                >
                    <img src={moreIcon} alt="mehr"/>
                </button>
                {isListOpen && (
                    <div className="dropdown-content">
                        <button className="dropdown-item"
                                onClick={() => this.selectItem(SEND_HISTORY)}>
                            Protokoll per E-Mail erhalten
                        </button>
                        <button className="dropdown-item"
                                onClick={() => this.selectItem(RESET_CHAT)}>
                            Chat zurücksetzen
                        </button>
                        <a className="dropdown-item" target={'_blank'} rel={'noreferrer'}
                           href={'https://solutions.resilience.hamburg/imprint'}>Impressum</a>
                    </div>
                )}
            </div>
        );
    }
}
