import './BotIsBooting.css';
import React from "react";
import {DEFAULT_BOT_MESSAGE} from "./BotIsBooting";
import BootImage from "./BootImage";


const BotIsDown = () => {
    return <div className="landing">
        <div>
            <h2>{DEFAULT_BOT_MESSAGE}</h2>
            <BootImage/>
        </div>
    </div>
}

export default BotIsDown;
