const primary = '#00B2BE'
const secondary = '#00B2BE'
const accent = '#007d85'
const grey = '#EEE'
const white = '#FFF'
const sendBoxBorder = `solid 10px ${primary}`
const nubSize = 10
const bubbleBorderWidth = 0
const borderRadius = 15

export const styleOptions_pflegerat = {
  // Root
  rootHeight: '100%',
  rootWidth: '100%',

  // common
  richCardWrapTitle: true,
  accent: primary,
  backgroundColor: grey,
  typingAnimationDuration: 2000,
  primaryFont: '"Solomon", sans-serif',

  hideUploadButton: false,
  sendBoxButtonColor: primary,
  sendBoxButtonColorOnFocus: accent,
  sendBoxButtonColorOnHover: accent,
  sendBoxBorderLeft: sendBoxBorder,
  sendBoxBorderTop: sendBoxBorder,
  sendBoxBorderRight: sendBoxBorder,
  sendBoxBorderBottom: sendBoxBorder,
  sendBoxPlaceholderColor: white,

  // bot bubbles
  bubbleNubOffset: 10,
  bubbleNubSize: nubSize,
  bubbleBorderWidth,
  bubbleBorderRadius: borderRadius,
  // user bubbles
  bubbleFromUserNubOffset: -10,
  bubbleFromUserNubSize: nubSize,
  bubbleFromUserBackground: secondary,
  bubbleFromUserBorderWidth: bubbleBorderWidth,
  bubbleFromUserBorderRadius: borderRadius,
  bubbleFromUserTextColor: white,
  // prompts,
  suggestedActionBorderColor: secondary,
  suggestedActionTextColor: secondary
}
