import './BotIsBooting.css';
import botFull from '../assets/bot_full.png';
import pflegerat from '../assets/pflegerat_white.jpg';
import React from "react";
import {PFLEGERAT, RESILO} from "../whitelabel/Resilo_default/all";

const BootImage = () => {
  if (process.env.REACT_APP_WHITELABEL === RESILO) {
    return <img src={botFull} alt="Chat Bot"/>
  } else if (process.env.REACT_APP_WHITELABEL === PFLEGERAT) {
    return <img src={pflegerat} alt="Chat Bot"/>
  } else {
    return <span/>
  }
}

export default BootImage;
