const greenLight = '#86D6A4'
const greenDark = '#53997D'
const turquoise = '#3FA3B0'
const grey = '#EEE'
const white = '#FFF'
const sendBoxBorder = `solid 10px ${greenLight}`
const nubSize = 10
const bubbleBorderWidth = 0
const borderRadius = 15

export const styleOptions_resilo = {
    // Root
    rootHeight: '100%',
    rootWidth: '100%',

    // common
    richCardWrapTitle: true,
    accent: turquoise, // card button color
    backgroundColor: grey,
    typingAnimationDuration: 2000,
    primaryFont: '"Solomon", sans-serif',

    hideUploadButton: false,
    sendBoxButtonColor: greenLight,
    sendBoxButtonColorOnFocus: greenDark,
    sendBoxButtonColorOnHover: greenDark,
    sendBoxBorderLeft: sendBoxBorder,
    sendBoxBorderTop: sendBoxBorder,
    sendBoxBorderRight: sendBoxBorder,
    sendBoxBorderBottom: sendBoxBorder,
    sendBoxPlaceholderColor: white,

    // bot bubbles
    bubbleNubOffset: 10,
    bubbleNubSize: nubSize,
    bubbleBorderWidth,
    bubbleBorderRadius: borderRadius,
    // user bubbles
    bubbleFromUserNubOffset: -10,
    bubbleFromUserNubSize: nubSize,
    bubbleFromUserBackground: turquoise,
    bubbleFromUserBorderWidth: bubbleBorderWidth,
    bubbleFromUserBorderRadius: borderRadius,
    bubbleFromUserTextColor: white,
    // prompts,
    suggestedActionBorderColor: turquoise,
    suggestedActionTextColor: turquoise
}
