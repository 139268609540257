import './App.css';
import React, {useEffect} from 'react';
import {Components, createDirectLine, createStore} from 'botframework-webchat';
import {styleOptions_resilo} from "./whitelabel/Resilo_default/styleOptions_resilo";
import {styleOptions_pflegerat} from "./whitelabel/Pflegerat/styleOptions_pflegerat";
import Header from "./components/Header";
import BotIsBooting from "./components/BotIsBooting";
import BotIsDown from "./components/BotIsDown";
import MarkdownIt from "markdown-it";
import BackButton from "./components/BackButton";
import {PFLEGERAT} from "./whitelabel/Resilo_default/all";
import BotActivityDecorator from "./components/BotActivityDecorator";

async function getDirectLineToken() {
  const customerName = process.env.REACT_APP_CUSTOMER_NAME;
  console.log('Fetching...');
  const res = await fetch(`https://directline-api.azurewebsites.net/api/GetConfig?code=FRc0zazuBBGGApZyTXOCoJ/HTvavfG2f8eg1cwRIJGWT8knc5KPv8Q==&instanceName=${customerName}`, {method: 'POST'});
  console.log('Fetching... Done');
  const {token} = await res.json();
  return token;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {directLine: undefined};
    this.styleOptions = styleOptions_resilo;
    this.whitelabel = process.env.REACT_APP_WHITELABEL;

    if (this.whitelabel === PFLEGERAT) {
      this.styleOptions = styleOptions_pflegerat;
      this.setFavicon(`/${this.whitelabel}.ico`)
      document.title = 'Pflegerat - Digitaler Pflegelotse';
    }

    let root = document.documentElement;
    root.style.setProperty('--primary', this.styleOptions.sendBoxButtonColor);
  }

  setFavicon(href) {
    console.log(href);
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = href;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  componentDidMount() {
    console.log('NO directLine -> getDirectLineToken');
    getDirectLineToken().then(token => {
      console.log('SET directline');
      this.setState({directLine: createDirectLine({token})});
    });
  }

  store = createStore({}, ({dispatch}) => next => action => {
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: 'webchat/join',
            value: {language: window.navigator.language}
          }
        });
      }
      return next(action);
    }
  );

  markdownIt = new MarkdownIt({html: true, linkify: true, typographer: true});
  renderMarkdown = text => this.markdownIt.render(text);

  // FIXME jede message activity kommt 2x mal! -> daher der delay anfangs? 

  activityMiddleware = () => next => (...setupArgs) => {
    const [card] = setupArgs
    switch (card.activity.from.role) {
      case "bot":
        if(card.nextVisibleActivity || card.activity.type === "typing") {
          return next(card);
        }
        return (...renderArgs) => (
          <BotActivityDecorator activity={card.activity} key={card.activity.id}>
            {next(card)(...renderArgs)}
          </BotActivityDecorator>
        );
      default:
        return next(card);
    }
  };

  render() {
    return !!this.state.directLine ?
      <Components.Composer
        directLine={this.state.directLine}
        store={this.store}
        renderMarkdown={this.renderMarkdown}
        // activityMiddleware={this.activityMiddleware}
        styleOptions={this.styleOptions}>
        <div className="container">
          <Header/>
          <BotIsBooting store={this.store}/>
          <BackButton/>
          <div className="web-chat-wrapper">
            <Components.BasicWebChat/>
          </div>
        </div>
      </Components.Composer>
      : <div className="container">
        <Header/>
        <BotIsDown/>
      </div>;
  }
}

export default App;
