import React, {useCallback} from "react";
import {hooks} from 'botframework-webchat-component';
import './BotActivityDecorator.css';
import voteUp from '../assets/icons/thumbs_up_green.png';
import voteDown from '../assets/icons/thumbs_down.png';

const {usePostActivity} = hooks;

const BotActivityDecorator = ({activity, children}) => {
  const feedbackUrl = 'https://resilofeeedback.azurewebsites.net/api/submitThumbVote?code=izBTiPaMmFJqOqMJ6EEOZ/0ACPna5P1JnpaAOA31LfHBsHudj/ScKw==';

  const handleUpvoteButton = useCallback(() => {
    const vote = {
      isUpvote: true,
      location: activity.text || activity.attachments[0].content.body,
      customerName: process.env.REACT_APP_CUSTOMER_NAME
    };
    console.log(vote);
    fetch(feedbackUrl, {method: 'POST', body: JSON.stringify(vote)}).then(r => console.log(r)).catch(e => console.error(e));
  }, [activity.attachments, activity.text]);

  const handleDownvoteButton = useCallback(() => {
    const vote = {
      isUpvote: false,
      location: activity.text || activity.attachments[0].content.body,
      customerName: process.env.REACT_APP_CUSTOMER_NAME
    };
    console.log(vote);
    fetch(feedbackUrl, {method: 'POST', body: JSON.stringify(vote)}).then(r => console.log(r)).catch(e => console.error(e));
  }, [activity.attachments, activity.text]);

  return (
    <div className="botActivityDecorator">
      <ul className="botActivityDecorator__buttonBar">
        <li>
          <button className="botActivityDecorator__button" onClick={handleUpvoteButton}>
            <img
              width={24}
              src={voteUp}
              alt={'+'}
            />
          </button>
        </li>
        <li>
          <button className="botActivityDecorator__button" onClick={handleDownvoteButton}>
            <img
              width={24}
              src={voteDown}
              alt={'-'}
            />
          </button>
        </li>
      </ul>
      <div className="botActivityDecorator__content">{children}</div>
    </div>
  );
};

export default BotActivityDecorator;
