import './PflegeratLogo.css';
import avatar from '../../assets/pflegerat.png';
import React from "react";

const PflegeratLogo = () => {
  return (
    <div className="pflegerat-avatar">
      <img src={avatar} alt="Pflegerat"/>
    </div>
  )
}

export default PflegeratLogo;
