import './Header.css';
import eugenLogo from '../assets/logos/apo_eugen.png';
import hzaLogo from '../assets/logos/apo_hza.png';
import miltenbergLogo from '../assets/logos/apo_miltenberg.png';
import peissenbergLogo from '../assets/logos/apo_peissenberg.png';
import regenbogenLogo from '../assets/logos/apo_regenbogen_mono.png';
import treburLogo from '../assets/logos/apo_trebur.png';
import stolleLogo from '../assets/logos/stolle.png';
import resilienceLogo from '../assets/logos/resilience.png';
import {Dropdown, RESET_CHAT, SEND_HISTORY} from "./Dropdown";
import React, {useCallback} from "react";
import {hooks} from 'botframework-webchat-component';
import ResiloLogo from "../whitelabel/Resilo_default/ResiloLogo";
import PflegeratLogo from "../whitelabel/Pflegerat/PflegeratLogo";
import {PFLEGERAT} from "../whitelabel/Resilo_default/all";

const {usePostActivity} = hooks;

const Header = () => {
  const [showDropdown, setShowDropdown] = React.useState();

  const postActivity = usePostActivity();
  const customerName = process.env.REACT_APP_CUSTOMER_NAME;
  const whitelabel = process.env.REACT_APP_WHITELABEL;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  const closeDropdown = () => {
    setShowDropdown(false);
  }

  const getCustomerBanner = (customerBanner) => {
    switch (customerBanner) {
      case 'EUGEN':
        return eugenLogo;
      case 'HOHENZOLLERN':
        return hzaLogo;
      case 'MILTENBERG':
        return miltenbergLogo;
      case 'PEISSENBERG':
        return peissenbergLogo;
      case 'REGENBOGEN':
      case 'PFLEGERAT_DEMO':
        return regenbogenLogo;
      case 'TREBUR':
        return treburLogo;
      case 'STOLLE':
        return stolleLogo;
      default:
        return resilienceLogo;
    }
  }

  const onDropdownSelection = (event) => {
    if (event === SEND_HISTORY) {
      sendHistory();
    } else if (event === RESET_CHAT) {
      resetChat();
    }
    closeDropdown();
  }

  const sendHistory = useCallback(() => {
    postActivity({
      name: 'sendProtocol',
      type: 'event',
    });
  }, [postActivity]);

  const resetChat = () => window.location.reload(false)

  return (
    <div className="header">
      {whitelabel === PFLEGERAT ? <PflegeratLogo/> : <ResiloLogo/>}
      <div className="customer-logo">
        <img
          src={getCustomerBanner(customerName)}
          alt={'Logo'}
        />
      </div>
      <Dropdown onSelection={onDropdownSelection} isListOpen={showDropdown}
                onClick={toggleDropdown}/>
    </div>
  )
}

export default Header;
