import './BotIsBooting.css';
import botFull from '../assets/bot_full.png';
import pflegerat from '../assets/pflegerat.png';
import React, {useEffect} from "react";
import {hooks} from "botframework-webchat-component";
import {PFLEGERAT} from "../whitelabel/Resilo_default/all";
import BootImage from "./BootImage";

const {useActivities, useConnectivityStatus} = hooks;
export const DEFAULT_BOT_MESSAGE = 'Einen Moment bitte...';
export const BOT_ERROR_MESSAGE = 'Leider läuft gerade nicht alles ganz rund. Probieren Sie es später nochmal!';

const landingImage = process.env.REACT_APP_WHITELABEL === PFLEGERAT ? pflegerat : botFull;

const BotIsBooting = () => {
    const [message, setMessage] = React.useState(DEFAULT_BOT_MESSAGE);
    const [receivedFirstBotMessage, setReceivedFirstBotMessage] = React.useState(false);
    const [hasConnectionError, setHasConnectionError] = React.useState(false);

    const activities = useActivities();
    const [connectivityStatus] = useConnectivityStatus();

    useEffect(() => {
        console.log(connectivityStatus);
        if (connectivityStatus === 'connected' && activities.length && activities[0].length > 1) {
            setReceivedFirstBotMessage(true);
            setHasConnectionError(false);
        } else if (connectivityStatus === 'error') {
            setHasConnectionError(true);
            setMessage(BOT_ERROR_MESSAGE);
            setReceivedFirstBotMessage(false);
        } else {
            setHasConnectionError(false);
            setMessage(DEFAULT_BOT_MESSAGE);
            setReceivedFirstBotMessage(false);
        }
    }, [connectivityStatus, activities])

    return receivedFirstBotMessage ? false : (<div className="landing">
        <div>
            <h2>{message}</h2>
            <BootImage/>
        </div>
        {hasConnectionError ? false : <div className="spinner"/>}
    </div>)
}

export default BotIsBooting;
